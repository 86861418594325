import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import marked from "marked"
import { graphql } from "gatsby"
const slugify = require("slugify")

const OurClients = ({ data }) => {
  const reviews = [
    {
      title: "Positive: Professionalism, Quality, Responsiveness, Value",
      review:
        "I have had the pleasure of working IT Specialists for over the past Eight years and I cannot say enough about the high quality service my organization receives. IT Specialists are very responsive to our needs and is always looking out for our best interest for safety and affordability. With out any doubts I highly recommend IT Specialist for IT needs.",
      name: "Wilfred Romero",
      company: "The Arc",
    },
    {
      title:
        "Colorado Canine Orthopedics has been working with IT Specialists for well over a decade and the IT Specialist team has been integral to our success as the largest veterinary, orthopedic surgical practice in the country.",
      review:
        "Our relationship with IT Specialists has been based on mutual respect and a true feeling of partnership whether solving an emergent problem or brainstorming about how improve upon our overall services. IT Specialists has provided help on a broad range of needs from phones to firewalls. Their group has had the key combination of being good listeners and offering a can-do attitude whether solving problems or initiating new technologies. IT Specialists is a service-oriented company, and in my opinion, the customer service they offer is exceptional. I have always found them to be readily available if we have problems, and very responsive and resourceful in finding solutions. I couldn’t be happier with our long term relationship.",
      name: "Mike Bauer",
      company: " Colorado Canine Orthopedic",
    },
    {
      title:
        "We have had many IT consultants and technicians in the past, and I can say with full-confidence, IT Specialists are the BEST.",
      review:
        "The customer service is first class, the account management is supreme, best in show technology advancements, and top-notch cyber security. I would highly recommend IT Specialists to those who are looking for peace of mind and innovative IT solutions.",
      name: "Asa Dyer",
      company: "OEL Worldwide Industries",
    },
    {
      title:
        "Reliability and fast response time is what IT Specialists does better than any other IT company!",
      review:
        "I like having my IT company local because I believe the response for service is the quickest and most personalized to our specific needs.  IT Specialists Managed Services approach has provided just that and it’s available in an all-inclusive package.",
      name: "Mary Casey",
      company: "Federal Document Shredding, RS.",
    },
    {
      title:
        "The knowledge and support IT Specialists provides for our organization surpasses what we would be able to maintain internally.",
      review:
        "The team of resources we have access to with IT Specialists has been extremely beneficial.  We don't have to rely on one or two people within our organization to have just a fraction of the knowledge that we get by working with IT Specialists. They have a good understanding of our budgets and needs and provide the proper recommendations for software and hardware based on those competing resources.  IT Specialists has a team of people who are easy to work with and provide quality services.",
      name: "Carol",
      company: "Our House, Inc.",
    },
    {
      title: "IT Specialists makes us feel like we are their only customer!",
      review:
        "The greatest benefit we receive from the MSP services is the quick and easy access to help with our IT needs.  It makes us feel like we are the only company that IT Specialists has which I absolutely know is not true. We feel like we are treated as royalty when it comes to their response to our technical problems and computer needs.<br/><br/>If you are searching for an IT group to help administer and take over your computer IT needs, I recommend you stop looking and hire IT Specialists.  You will get your immediate needs resolved and begin running at peak efficiency. I made a promise with them a long time ago, they don't attempt to fix their teeth and I won't attempt to fix my computer problems. I trust them with the technical services portion of my business which allows me to focus on my patients and be more profitable day to day.",
      name: "Jeffery Platt",
      company: "Lasting Impressions Dental",
    },
    {
      title: "IT Specialists provides peace of mind!",
      review:
        "If you’re looking for an IT Company that is reliable, proactive and responds immediately, look no further. IT Specialists conducts business with a consistent professional demeanor and level of service at all times.",
      name: "Lisa Hazleton",
      company: "Indra, LLC.",
    },
  ]

  const urlHash =
    typeof window !== "undefined" ? window.location.href.split("#")[1] : ""

  return (
    <Layout>
      <Seo
        title="Our Clients"
        description={data.site.siteMetadata.description}
      />
      <div className="page-headline">
        <div className="container">
          <h6 className="text-white-50 text-uppercase">Reviews</h6>
          <h1>Our Clients</h1>
        </div>
      </div>
      <div className="page-content pt-3 bg-light">
        <div className="container">
          <div className="row justify-content-around">
            {reviews.map((review, idx) => (
              <div
                className="col-md-8 col-lg-6"
                style={{ paddingTop: "100px" }}
                id={slugify(review.name.toLowerCase())}
                key={idx}
              >
                <div
                  className={`review-box ${
                    slugify(review.name.toLowerCase()) === urlHash
                      ? "selected"
                      : ""
                  }`}
                >
                  <h4 className="mb-0">{review.title}</h4>
                  <p
                    className="my-4 font-italic"
                    dangerouslySetInnerHTML={{
                      __html: marked(review.review),
                    }}
                  />
                  <div className="review-stars">
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                  </div>
                  <h4 className="mb-0 text-primary">{review.name}</h4>
                  <span className="small text-uppercase text-muted d-block">
                    {review.company}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OurClients

export const OurClientsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
